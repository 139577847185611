<template>
  <div class="_bg-default _100vh pb-3">
    <div v-if="survey" class="py-5">
      <div class="my-3">
        <h3>My Survey</h3>
        <v-divider></v-divider>
      </div>
      <v-row>
        <v-col cols="12" md="4" sm="6">
          <v-card
            height="210"
            class="radius-card"
            elevation="0"
            to="/psikolog/create-survey"
          >
            <div
              class="d-flex justify-center align-center"
              style="height: 100%"
            >
              <v-btn fab depressed color="blue lighten-4"
                ><v-icon size="70" color="blue">mdi-plus</v-icon></v-btn
              >
            </div>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="6"
          v-for="item in survey.data"
          :key="item.id"
        >
          <v-card height="210" class="radius-card" elevation="0">
            <v-toolbar dense flat class="pt-2 px-0">
              <div
                class="d-flex align-center"
                v-for="user in item.relationships"
                :key="user.id"
              >
                <div v-if="user.user">
                  <v-avatar class="mr-2">
                    <img
                      :src="
                        user.user.data.photo_profile
                          ? `${env}/upload/photo_profile/${item.user_id}/${user.user.data.photo_profile}`
                          : `${env}/images/logo.png`
                      "
                      class="img-fit"
                      alt=""
                    />
                  </v-avatar>
                </div>
                <h4 v-snip="1">{{ user.user.data.nama_lengkap }}</h4>
              </div>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-title
              class="d-flex justify-center pa-2"
              style="height: 100px"
            >
              <h3 class="text-center" v-snip="2">{{ item.judul }}</h3>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-actions>
              <v-chip
                class="mx-2 white--text"
                :color="
                  item.kategori == 'Psikologi Klinis'
                    ? 'a__'
                    : item.kategori == 'Psikologi Pendidikan'
                    ? 'b__'
                    : 'c__'
                "
              >
                {{ item.kategori }}
              </v-chip>
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                fab
                depressed
                dark
                small
                @click="deleteItem(item)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                color="blue"
                fab
                depressed
                dark
                small
                content="view detail"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100
                }"
                :to="`/survey/${item.id}/view`"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </v-card-actions>
            <!-- {{ survey }} -->
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "survey",

  data() {
    return {
      currentPage: 1,
      limit: 10,
      valueDeterminate: 50
    };
  },
  computed: {
    ...mapState({
      survey: state => state.survey.my_survey,
      last: state => state.admin.last_page,
      env: state => state.API_URL,
      role: state => state.role,
      id: state => state.id
    })
  },
  mounted() {
    this.fetchItem();
  },
  methods: {
    fetchItem() {
      this.$store.dispatch("survey/mySurvey", this.id);
    },
    deleteItem(item) {
      //delete  function
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          //if ok then hit api delete iten
          this.$store.dispatch("survey/deleteSurvey", item.id).then(() => {
            this.fetchItem(); //if success refetch data all
            Swal.fire({
              title: "Deleted",
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
              position: "top",
              toast: true
            });
          });
        }
      });
    }
  }
};
</script>

<style scoped></style>
